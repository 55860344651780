import React from 'react'
import Logo from "../assets/logo.svg"
import { Link } from 'gatsby'

const HeaderSlim = () => {

    return(
        <header>
            <div className={`fixed top-0 left-0 right-0 z-50 py-6 lg:py-10 transition-colors duration-300 ease-in-out bg-primary`}>
                <div className="container flex items-center justify-center px-5 mx-auto lg:justify-between">
                    <Link to="/"><Logo /></Link>
                    <div className="items-center hidden space-x-5 lg:flex">
                        <a href="mailto:contact@clickflow.co.uk" className="py-3 transition-colors duration-200 ease-in-out bg-white rounded-full px-7 hover:bg-accent hover:text-white">Enquire Now</a>
                        {/* <div className="flex items-center space-x-2.5">
                            <a href="https://twitter.com/click_flow"><Twitter /></a>
                            <a href="https://www.linkedin.com/in/jonathangalloway19/"><LinkedIn /></a>
                        </div> */}
                    </div>
                </div>
            </div>
        </header>
    )
}

export default HeaderSlim