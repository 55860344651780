import * as React from "react"
import Footer from "../components/footer"
import Helmet from "react-helmet"
import HeaderSlim from "../components/headerSlim"

const PrivacyPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Clickflow | Privacy</title>
        <meta name="description" content="Data insights driving business performance" />
      </Helmet>
      <HeaderSlim />
      <main className="font-body">

        <section className="pt-48 pb-20 text-lg lg:pt-60">
            <div className="container px-5 mx-auto">
                <h1>Privacy Policy</h1>
            	<h2>1. The Policy</h2>
                <p>.1 Everyone has the right to know how their personal data is handled by the companies they interact with. At Clickflow, we acknowledge that during the course of our activities as a Creative Agency, we will collect, store, and process personal information about our staff. We know that we need to treat this data in a lawful and appropriate manner.</p>
                <p>.2 We may be required to handle several types of information, including details of current, past and prospective employees, suppliers, clients, other people and organisations that we communicate with, and data supplied to us by customers.</p>
                <p>We may hold this information on paper, on a computer, or on other media. Regardless, all information held by Clickflow will be subject to the legal safeguards outlined in the General Data Protection Regulation 2018 (the GDPR). The GDPR imposes strict regulations on how we may use that information.</p>
                <p>.3 This Data Protection Policy does not form part of any employee's contract of employment, and it may be amended or edited at any time. Breaching this policy is a serious matter and could result in disciplinary action.</p>
                <h2>2. Status of the Policy</h2>
                <p>2.1 This policy is designed to clearly set out Clickflow's rules on data protection and the legal conditions associated with it. Legal conditions related to obtaining, handling, processing, storing, transporting, and destroying personal information must be satisfied in order for us to be a GDPR compliant company.</p>
                <p>2.2 The Data Protection Officer (DPO) is responsible for ensuring GDPR compliance, as well as compliance with this policy.</p>
                <p>2.3 If you believe that the policy has not been followed with regards to personal data - whether your own or someone else's - you should raise the matter with your line manager or with the DPO. </p>
                <h2>3. Definition of Data Protection Terms</h2>
                <p>3.1 Data is information that is stored electronically, on a computer, or in a paper-based filing system. </p>
                <p>3.2 A data subject is any person whose personal data is being collected, held or processed. All data subjects have legal rights in relation to the processing and use of their personal data. </p>
                <p>3.3 Personal data is, according to the Information Commissioner's Office, “any information relating to an identified or identifiable natural person ('data subject'); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.”</p>
                <p>3.4 A data controller is any person or organisation that determines the purposes for which and the means by which personal data is processed. So, if your company or organisation decides 'why' and 'how' the personal data should be processed, it is the data controller. We are the data controller of all personal data used in our business. </p>
                <p>3.5 A data user is anyone whose work involves the use of personal data: our employees are data users. They are bound by a duty to protect the information they handle by following our data protection and security policies at all times. </p>
                <p>3.6 A data processor is anyone who processes personal data on behalf of a data controller. This can include suppliers that handle personal data on our behalf. </p>
                <p>3.7 The 'processing' of personal data refers to any activity that involves using the data. Data processing includes obtaining, recording, and holding the data in questions, as well as carrying out any operation or set of operations on the data such as organising, amending, retrieving, using, disclosing, erasing, or destroying it. Processing also includes transferring personal data to any third parties. </p>
                <p>3.8 Sensitive personal data involves any data relating to a person's ethnic or racial origin, political opinions, religious or non-religious beliefs, mental and physical health, trade union membership, and sexual life and condition. It also includes the commission of, or proceedings for, any offence committed (or alleged to have been committed) by any individual, the disposal of such proceedings, or the sentence of any court in such proceedings. Because of this, sensitive personal data can only be processed under extremely strict principles, and under normal circumstances will require the express permission of the individual in question. </p>
                <h2>4. Data Protection Principles</h2>
                <p>Anyone involved in the processing of personal data at Clickflow is legally required to comply with the eight enforceable principles of good practice. These provide that personal data must be:</p>
                <p>(a) Processed fairly and lawfully.</p>
                <p>(b) Processed for limited purposes and in an appropriate way.</p>
                <p>(c) Adequate, relevant and not excessive for the purpose.</p>
                <p>(d) Accurate.</p>
                <p>(e) Not kept longer than necessary for the purpose.</p>
                <p>(f) Processed in line with data subjects' rights.</p>
                <p>(g) Secure.</p>
                <p>(h) Not transferred to people or organisations situated in countries without adequate protection.</p>
                <h2>5. Fair and Lawful Processing </h2>
                <p>5.1 The purpose of the Act is not to prevent personal data being processed, but instead to ensure that any processing is done fairly and without adversely affecting the rights of the data subject. The data subject must be made aware of who the data controller is (Clickflow Ltd, in this case), who the data controller's representative is (Kirsty Hart, the Data Protection Compliance Manager), the purpose for which the data is to be processed by Clickflow, and the identities of anyone to whom the data may be disclosed or transferred. </p>
                <p>5.2 Lawful processing of personal data demands that certain conditions be met. These may include requirements that the data subject has provided explicit consent for the processing, or that the processing itself is necessary for the legitimate interest of the data controller or the party to whom the data is disclosed.</p>
                <h2>6. Processing for Limited Purposes</h2>
                <p>Personal data processed by Clickflow may only be processed for the specific subjects either consented to by the data subject, or for any other purposes specifically permitted by the Act. </p>
                <p>Because of this, personal data must not be collected for one purpose, and then used for another. Should the personal data need to be used for any other purpose, the data subject will need to be notified and then consent to the new processing. </p>
                <h2>7. Adequate, relevant and non-excessive processing</h2>
                <p>When collecting personal data from a data subject, Clickflow will notify them of the specific purpose for which the data will be used. Then, data will be collected only enough to satisfy the requirements of this purpose. </p>
                <p>Any data that is not necessary for this purpose will not be collected by Clickflow in the first place. </p>
                <h2>8. Accurate Data </h2>
                <p>Personal data must be accurate and also kept up to date. Information that is misleading or incorrect is not accurate, and therefore steps should be taken to check the accuracy of any personal data at the point of collection, and at regular intervals afterwards.</p>
                <p>Legally, out-of-date or inaccurate data should be destroyed. </p>
                <h2>9. Timely Processing</h2>
                <p>Personal data will not be kept at Clickflow for longer than is necessary for the purpose. Data that is no longer required will be destroyed or erased from our systems. </p>
                <h2>0. Processing in line with data subject's rights</h2>
                <p>Data that is handled by Clickflow will be processed in line with the rights of the data subject. Data subjects have a right to:</p>
                <p>(a) Request access to any data held about them by a data controller.</p>
                <p>(b) Prevent the processing of their data for direct-marketing purposes.</p>
                <p>(c) Ask to have inaccurate data amended.</p>
                <p>(d) Prevent processing that is likely to cause damage or distress to themselves or anyone else.</p>
                <h2>1. Data Security</h2>
                <p>1.1 At Clickflow, we must be sure to take appropriate security measures against unlawful or unauthorised processing of personal data, as well as against the accidental loss of - or damage to - personal data. </p>
                <p>1.2 To be in accordance with The Act, Clickflow must put in place technologies and procedures to maintain the security of all personal data from the point of collection to the point of erasure or destruction. Personal data collected by Clickflow can only be transferred to a third-party data processor if they agree to comply with these procedures and policies, or if they put in place adequate measures themselves. </p>
                <p>1.3 To maintain data security, Clickflow must guarantee the confidentiality, availability, and integrity of the personal data, which is defined as follows:</p>
                <p>(a) Confidentiality means that only people who are authorised to use the data will be able to access it</p>
                <p>(b) Availability means that any authorised users should be able to access the data if, and only if, they need it for authorised purposes. Personal data at Clickflow is stored on our central computer system instead of individual PCs. </p>
                <p>(c) Integrity means that personal data should be accurate and suitable for the purpose for which it is processed. When Clickflow processes data for clients we only keep the relevant details needed for the pre-agreed data usage purposes. </p>
                <p>1.4 Security procedures at Clickflow are important for satisfying the criteria mentioned in 11.3. Our internal security procedures include:</p>
                <p>(a) Securely locked filing cabinets, desks, and cupboards for storing physical copies of confidential information and personal data </p>
                <p>(b) Entry controls - at Clickflow, we will immediately report any unauthorised individual found to have accessed/be accessing an entry-controlled area. </p>
                <p>(c) Methods of disposal - At Clickflow, we must shred paper documents containing confidential information; as well as physically destroy or wipe hard-drives, CDs, and USBs containing confidential or personal data when they are no longer required.</p>
                <p>(d) Data users at Clickflow understand the importance of logging off their computer when it is left unattended, as well as ensuring confidential information cannot be seen by passers-by. </p>
                <p>(e) Clickflow's data servers (which may store confidential information or data) is protected by encryption protocols, secure passwords, and firewalls. </p>
                <h2>2. Dealing with Subject Access Requests</h2>
                <p>Data subjects have the right to formally request any information that we hold about them by enquiring in writing to Clickflow. Any member of staff who receives a written request should forward it to the Data Protection Compliance Manager (Kirsty Hart) immediately </p>
                <h2>3. Providing Information over the Telephone </h2>
                <p>At Clickflow, all our staff will; at some point; have to deal with a telephone enquiry. It is therefore important that they know to be careful about disclosing any personal information held by us. Staff at Clickflow should:</p>
                <p>(a) Check the caller's identity to make sure that information is only given to a person who is entitled to it.</p>
                <p>(b) If the member of staff is not sure about the caller's identity, they may suggest that the caller put their request in writing in order to allow for further identity checks. </p>
                <p>(c) Refer to the DPCM (Kirsty Hart) for assistance in a difficult situation. Staff at Clickflow retain the right not to be bullied into disclosing personal information. </p>
                <h2>4. Monitoring and Review of the Policy </h2>
                <p>4.1 This policy is reviewed annually by Clickflow staff. Recommendations for any amendments are reported and considered by all senior members of staff. </p>
                <p>4.2 As time goes on, we will continue to regularly review the effectiveness of this policy to ensure it is achieving its stated objectives. </p>
                <h2>5. Controlling your Personal Information</h2>
                <p>Clickflow will not sell, distribute, or lease your personal information to third parties unless we have your explicit consent, or are required by law to do so. </p>
                <p>As the data subject, you have the right to restrict the collection or use of your personal information in the following ways:</p>
                <p>(a) You may submit a request for details of any personal information which we hold about you under GDPR.</p>
                <p>(b) If you believe that any of the information we are holding on you is incomplete or incorrect, please write to us at the above address to correct this promptly. </p>
                <h2>6. Cookies</h2>
                <p>6.1 Our website (<a href="https://www.clickflow.co.uk/">https://www.clickflow.co.uk/</a>) uses cookies. Cookies help us to provide you with a good experience when you browse our website and allow you to make full use of the features available on our website. We do not use cookies to collect or record information such as your name, address or personal details.</p>
                <p>6.2 We use cookies on our website for one purpose, and that is to collect statistical information about how visitors use our website, so we can improve the way our website works and measure our success. By recording such statistics as browser usage, visitor location, operating system etc. we can measure and improve on how we manage and maintain our site, in order to give you an ultimately better visitor experience.</p>
                <p>6.3 Most web browsers automatically accept cookies. Unless you have adjusted your browser settings so that it will refuse cookies, cookies will be set when you access our website. By using and browsing our website, you consent to cookies being used in accordance with this Cookie Policy. If you do not consent, please understand that certain features of our site may not function as intended. Please go to the How To Manage Cookies section for information on how to manage and disable cookies in your web browser.</p>
                <p>6.4 Below is a list of the main cookies that are set by us when you access our website and a description of what we use them for:</p>
                <p>(1) _utma</p>
                <p>(2) _utmb</p>
                <p>(3) _utmc</p>
                <p>(4) _utmz</p>
                <p>These cookies enable Google Analytics software. It helps us take and analyse visitor information such as browser usage and new visitor numbers. That information helps us to improve the website and your visitor experience, and to make our content relevant. The data stored by these cookies never shows personal details from which your individual identity can be established.</p>
                <p>6.5 Flash cookies: We may display video content on our website using the Adobe Flash Player, through services such as YouTube and Vimeo. Adobe uses Flash cookies to improve your experience as a user. If you wish to disable or delete a Flash cookie, please visit Adobe Flash Player Security Settings. Please bear in mind that if you disable Flash cookies you will be unable to access certain content on our website which uses Adobe Flash Player, such as our video content.</p>
                <p>6.6 Third party cookies: Some cookies may be set by third parties when you visit our website. These third parties may be suppliers who partner with us to deliver our website, companies that participate with us in affiliate marketing programmes and other third parties. These cookies are controlled by the third parties, so please check these third-party websites for more information about these cookies and how to manage them. These third party cookies do not collect personal data from which the third party would be able to identify individual customers.</p>
                <p>6.7 Who Is Visiting: Contained within our website is tracking code from Who Is Visiting. Who Is Visiting will track activity on the website and provide Clickflow with information on the IP address of the requesting computer, the date and duration of the user's visit, and the web pages which the user visits. It does not, and cannot, provide information on who has visited the website. It provides information on what company. More information can be found at https://www.whoisvisiting.com/.</p>
                <p>6.8 Social buttons: If you share Clickflow content with friends through social networks  such as Facebook, Google and Twitter  you may be sent cookies from these third party websites. We cannot control the setting of these cookies, so we suggest you check the third-party websites for more information about their cookies and how to manage them.</p>
                <p>6.9 Managing cookies: You can enable or disable cookies by changing your website browser settings to accept or reject cookies as required. How you do this will depend on the browser you use. We have provided further information below on how to check which browser you use and how to manage cookies. Please bear in mind that if cookies are not enabled on your computer, your activities may be restricted whilst browsing our website, and certain content may not be available to you.</p>
                <p>(1) Checking which browser you use</p>
                <p>(i) If you are using a PC, go to Helpand select the Aboutoption.</p>
                <p>(ii) If you are using a Mac, click on the Apple menu within the browser and select the About option.</p>
                <p>(2) Once you know which browser you are using, follow the instructions below to check if you have cookies enabled on your computer:</p>
                <p>(i) Checking cookies for PCs (Microsoft Internet Explorer 6, 7, 8)</p>
                <ul>
                <li>Go to Tools at the top of your browser window. Select 'Internet options.' Click on the Privacy tab</li>
                <li>Ensure that your Privacy level is set to Medium or below to enable cookies</li>
                <li>In Mozilla Firefox: Go to Toolsand select Options, click the Privacy icon, then click on Cookies and choose allow sites to set cookies.</li>
                <li>In Google Chrome: Go to Toolsand select Options, click Under the Hoodand select the Content settingsbutton within the Privacysection, then choose Allow local data to be set.</li>
                </ul>
                <p>(ii) Checking cookies for Macs</p>
                <ul>
                <li>In Safari on OSX: Click Safari and selectPreferences, then click Securitythen accept cookies. </li>
                <li>In Mozilla and Netscape on OSX: Click on Mozilla or Netscape and select preferences, find Cookiesunder Privacy &amp; Security, then select Enable cookies for the originating web site only.</li>
                <li>In Opera on OSX: Click Menu and select Settings, select Preferences and then Advanced, and then select Accept cookies.</li>
                </ul>
                <p>6.10 If you are not happy with the way that we use cookies then you should delete Clickflow cookies having visited the site, or you should browse the site using your browser's anonymous usage setting</p>
            </div> 
        </section>

      </main>
      <Footer />
    </>
  )
}

export default PrivacyPage
